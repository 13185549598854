import * as Sentry from "@sentry/browser";

import { saveToLocalStorage } from "utils/localStorage";

import { ACCESS_TOKEN_PARAM } from "const";

const checkInvalidTokenMessage = (errorMessage: string) => {
  if (
    errorMessage.toLocaleLowerCase().includes("token verification failed") ||
    errorMessage.toLocaleLowerCase().includes("not authenticated")
  ) {
    saveToLocalStorage(ACCESS_TOKEN_PARAM);
    try {
      window.location.replace("/");
    } catch {
      // Firefox throws an error?!? "Should not already be working."?
      // This is JavaScript, for God's sake. Not some f***ing PHP!
    }
    return true;
  }

  return false;
};

const isDoubleFirebaseInitError = (errorMessage: string) => {
  return errorMessage.toLocaleLowerCase().includes("the default firebase app already exists");
};

const isSkippedMessage = (errorMessage: string): boolean => {
  return checkInvalidTokenMessage(errorMessage) || isDoubleFirebaseInitError(errorMessage);
};

export const processApiError = (error: any) => {
  if (error === undefined || error === null) return;

  console.error(error);

  const { message } = error;
  if (typeof message === "string") {
    if (isSkippedMessage(message)) return;

    Sentry.withScope(scope => {
      scope.setExtras({ graphqlError: message });
      Sentry.captureException(new Error(message));
    });
  }
};

interface IRequestData {
  assignmentId: string;
  selfEvaluation: number | null;
  userAnswers: string[] | null;
  timeToSolve: number;
}

export const processExerciseError = (error: any, data: IRequestData) => {
  if (error === undefined || error === null) return;

  console.error(error);

  const { message } = error;

  if (typeof message === "string") {
    if (isSkippedMessage(message)) return;

    const parsedData = { ...data, userAnswers: data.userAnswers?.join(", ") };

    Sentry.withScope(scope => {
      scope.setExtras({ graphqlError: message, requestData: parsedData });
      Sentry.captureException(new Error(message));
    });
  }
};

export const logGeneralExerciseError = (error: unknown, id: string | undefined, data: unknown) => {
  Sentry.withScope(scope => {
    scope.setExtras({ id, data: JSON.stringify(data) });
    Sentry.captureException(error);
  });
};
